<template>
  <van-popup 
    :value="value"
    :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)' }"
    :style="{ width: '100%', maxHeight: '80%', minHeight: '35%', background: '#1E232D', padding: '0 10px' }"  
    :transition-appear=true
    position="bottom" 
    duration="0.5"
    @input="val => this.$emit('input', val)"
    @close='closePop'
    round
  >

    <div class="picker-wrap">
      <div class="picker-title">
        <div class="title-left">{{ $t('userDetail.heightAndWeight') }}</div>
        <div class="title-right" @click="handleConfirm">{{ $t('common.sure') }}</div>
      </div>
      <van-picker
        title="身高体重"
        :columns="columns"
        ref="HwPicker"
      />
    </div>

  </van-popup>
</template>
<script>

export default {
  name:'',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        // 第一列
        {
          values: ['160cm','161cm','162cm','163cm','164cm','165cm','166cm','167cm','168cm','169cm','170cm','171cm','172cm','173cm','174cm','175cm','176cm','177cm','178cm','179cm','180cm','181cm','182cm','183cm','184cm','185cm','186cm','187cm','188cm','189cm','190cm','191cm','192cm','193cm','194cm','195cm','196cm','197cm','198cm','199cm','200cm'],
          defaultIndex: 2,
        },
        // 第二列
        {
          values: ['40kg','41kg','42kg','43kg','44kg','45kg','46kg','47kg','48kg','49kg','50kg','51kg','52kg','53kg','54kg','55kg','56kg','57kg','58kg','59kg','60kg','61kg','62kg','63kg','64kg','65kg','66kg','67kg','68kg','69kg','70kg'],
          defaultIndex: 2,
        }
      ]
    }
  },
  methods: {
    closePop() {
      this.$emit('input', false)
    },
    handleConfirm() {
      let val = this.$refs.HwPicker.getValues()
      this.$emit('getSelected', val)
      this.closePop()
    }
  },
  mounted() {
  }
}
</script>


<style lang="scss" scoped>
.picker-wrap {
  margin: 20px 0;
  background-color: #1E232D;

  .picker-title {
    text-align: center;
    font-size: 20px;
    font-weight: blod;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    .title-right {
      color: #FD2084;
    }
  }
}

/deep/.van-picker__columns {
  background: #1E232D;
}
/deep/.van-ellipsis {
  color: #fff;
}

/deep/.van-picker__mask {
  background-image: none;
}

// .btn-groups {
//   margin: 10px 0;
//   display: flex;
//   justify-content: space-between;
//   .btn {
//     width: 45%;
//     height: 35px;
//   }
// }

.van-popup--round {
  border-radius: 5px 5px 0 0;
}

</style>